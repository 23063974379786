import { guestServiceProvider } from "../ServiceProvider";
import { getCrsFromId } from "./getCrsFromId";

export const fetchReservationDetails = async (
  params: {
    resId: string;
    lastName: string;
    hotelCode?: string;
  },
  source = null
): Promise<
  | {
      error: boolean;
      errorSummary: any;
      value?: undefined;
    }
  | {
      error: boolean;
      value: any;
      errorSummary?: undefined;
    }
> => {
  const resId = params.resId.toUpperCase();
  const lastName = params.lastName.trim().toLowerCase();
  const crs = getCrsFromId(/* resId */);
  let returnData;
  try {
    const guestService = await guestServiceProvider();
    const response = await guestService.getReservationDetails(resId, lastName);
    if (response.reservation.statusCode === 404) {
      returnData = {
        error: true,
        errorSummary:
          "You entered an invalid Confirmation Number or last name. Please try again.",
      };
    } else {
      returnData = {
        error: false,
        value: { ...response.reservation, Crs: crs },
      };
    }
  } catch (error) {
    returnData = {
      error: true,
      errorSummary: "Oops!! Something went wrong, please try again.",
    };
  }
  return returnData;
};

export default fetchReservationDetails;
