import { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useDispatch } from "react-redux";
import { hydrateBrandState } from "../redux/slices/Brand/brand";

const BrandData = () => {
  const dispatch = useDispatch();

  const data = useStaticQuery(graphql`
    query BrandData {
      allBrand(
        filter: {
          relationships: {}
          field_sites: { elemMatch: { drupal_internal__target_id: { eq: 31 } } }
        }
      ) {
        edges {
          node {
            id
            name
            brand_id: drupal_internal__id
            brand_code
            field_brand_stripe_color {
              color
            }
            field_color {
              color
            }
            path {
              alias
            }
            relationships {
              field_brand_stripe_logo {
                publicUrl
              }
              field_logo {
                publicUrl
                gatsbyImage(
                  width: 200
                  height: 30
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
              field_logo_alternate {
                uri {
                  url
                }
              }
              paragraphs: field_sections {
                type: __typename
                ...ParagraphPageBanner
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const _brands = data.allBrand.edges.map((x) => x.node);
    dispatch(hydrateBrandState(_brands));
  }, [data, dispatch]);

  return null;
};

export default BrandData;
