import { getLoggedStatus } from "../helpers";

export const fireMemberRatePresent = (pageType: string, room: any): void => {
  if (pageType === "Search Results") {
    const isDuplicate = window.dataLayer.some((event: any) => {
      return (
        event.event === "memberRatePresent" &&
        event.pageType === pageType &&
        event.hotelDetails?.hotelCode === room.hotelCode
      );
    });

    if (isDuplicate) {
      return;
    }
  }
  if (pageType === "Checkout Guest Details") {
    const isDuplicate = window.dataLayer.some((event: any) => {
      return (
        event.event === "memberRatePresent" &&
        event.pageType === pageType &&
        event.roomDetails?.RateCode === room.RateCode
      );
    });

    if (isDuplicate) {
      return;
    }
  }

  const loggedState = getLoggedStatus();
  window.dataLayer = window.dataLayer || [];
  const layerData = {
    event: "memberRatePresent",
    pageType: pageType,
    loggedState: loggedState,
    ...(pageType === "Search Results"
      ? { hotelDetails: room }
      : { roomDetails: room }),
  };

  window.dataLayer.push(layerData);
};
