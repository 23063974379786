import differenceInDays from "date-fns/differenceInDays";
import { TP_REDEMPTION_RATE } from "../@types/Constants";
import { store } from "../redux/store";
import { Storage } from "../utils/storage";
import { ServiceProvider } from "./ServiceProvider";
import {
  checkEditFlowByPathname,
  checkUserRedemptionEligiblity,
} from "./redemption";
/**
 *
 * @param {string} crs
 * @param {string} crsCode
 * @param {string} checkin
 * @param {string} checkout
 * @param {number} adults
 * @param {number} children
 * @param {string} promotionCode
 * @param {string} groupCode
 * @param {string|null} rateCode
 * @returns {string}
 */
// ASE - Availibilty Call
export const buildFetchRatesUrl = (
  crs: string,
  crsCode: string,
  checkin: string,
  checkout: string,
  adults: number,
  children: number,
  promotionCode: string | null,
  groupCode: string | any[] | null,
  rateCode: string | null = null,
  chidlrenAges: Array<string | number> = [],
  ratePlanFilterCode?: string | null,
  discount?: string | null,
  EditReservationRate?: string | null,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  siteID?: any
): string => {
  let apiUrl = `${process.env.GATSBY_CORE_BASE_URL}/api/${crs}/availability?_format=json`;
  const siteId = siteID || process.env.SITE_ID || 31;
  apiUrl += `&siteId=${siteId}`;
  apiUrl += `&Quantity=1`;
  apiUrl += `&Start=${checkin}`;
  apiUrl += `&End=${checkout}`;
  apiUrl += `&Adults=${adults}`;
  apiUrl += `&Children=${children}`;
  apiUrl += `&ChildrenAges=${chidlrenAges.join(",")}`;
  apiUrl += `&HotelCode=${crsCode}`;

  // Promotion code.
  if (promotionCode !== null && promotionCode.length) {
    // apiUrl += `&PromotionCode=${promotionCode}&ExactMatchOnly=true`;
    apiUrl += `&PromotionCode=${promotionCode.toUpperCase()}`;
  }
  // Discount code AARP senior
  if (discount === "AARP") {
    const discountPromo = "SEN55";
    apiUrl += `&PromotionCode=${discountPromo}`;
  }

  // Group code.
  if (groupCode !== null && groupCode.length) {
    apiUrl += `&GroupCode=${groupCode}`;
  }
  // Detailed rates
  if (rateCode !== null) {
    // Crs-specific params.
    if (crs === "synxis") {
      apiUrl += "&AvailReqType=NonRoom";
    } else {
      apiUrl += "&ResponseType=RateRoomServiceItem";
    }
    // Rate plan code.
    apiUrl += `&RatePlanCode=${rateCode}`;
  }

  // Rate plan filter code
  // if(!rateCode)  {
  const defaultRatePlanFilter = store.getState().ratePlanFilter || "";
  let specialRatePlanFilter = null;
  switch (discount) {
    case "AAA":
      specialRatePlanFilter = "AAA";
      break;
    case "GOV":
    case "MIL":
      specialRatePlanFilter = "GOV";
      break;
    case "PKG":
      specialRatePlanFilter = "PKG";
  }

  const filter =
    specialRatePlanFilter || ratePlanFilterCode || defaultRatePlanFilter;
  if (filter) {
    apiUrl += `&RatePlanFilterCode=${filter}`;
  }
  if (EditReservationRate) {
    if (rateCode !== null) {
      apiUrl += `&RatePlanCode=${rateCode}`;
    } else {
      apiUrl += `&RatePlanCode=${EditReservationRate}`;
    }
    apiUrl += `&PromotionCode=${EditReservationRate}`;
    apiUrl += `&GroupCode=${EditReservationRate}`;
  }
  // }
  return apiUrl;
};

export const getCRSRatesASE = async (
  adults: number,
  children: number,
  chidlrenAges: string,
  crsHotelCode: string,
  chainId: string,
  start: string,
  end: string,
  availReqType: string,
  promotionCode: string,
  ratePlanCode: string,
  ratePlanFilterCode: string,
  abortController: string
): Promise<any> => {
  const guestService = await ServiceProvider.Guest.getGuestClient();
  const hotelAvailablityResponse = await guestService.getHotelAvailability(
    adults,
    children,
    chidlrenAges,
    crsHotelCode,
    chainId,
    start,
    end,
    "NonRoom",
    promotionCode,
    ratePlanCode,
    ratePlanFilterCode,
    abortController
  );
  const hotelData = hotelAvailablityResponse?.hotelAvailablity?.queryHotel;
  return hotelData;
};

/**
 *
 * @param {Array<{Object}>} rooms
 * @param {string} discountCode
 * @param {string} promotionCode
 * @param {string} groupCode
 * @returns {null|[]}
 */

export const fetchRoomsAndRatesASE = (
  rooms: { [x: string]: any } | null | undefined,
  discountCode = null,
  promotionCode = null,
  groupCode: string | null,
  checkinDate: string | null,
  checkoutDate: string | null,
  redemptionItem: { currencyRequired: string } | undefined
): any[] | null => {
  const filteredRooms: any[] = [];
  const isLoggedIn = Storage.GetLocalStorageValue("isLoggedIn");
  const isRedemptionPromoCode = promotionCode
    ? TP_REDEMPTION_RATE.includes(promotionCode)
    : groupCode
    ? TP_REDEMPTION_RATE.includes(groupCode)
    : false;
  const isEditFlow =
    typeof window !== "undefined" &&
    checkEditFlowByPathname(window.location.pathname);
  const isMultiroom = !isEditFlow && store.getState().search?.rooms?.length > 1;
  let includeRedemptionRate = true;
  if (rooms !== null && rooms !== undefined) {
    rooms.forEach((room: any) => {
      const rates: any[] = [];
      const selectedRates: any[] = [];
      const discountCodes = ["AAA", "GOV", "PKG"];
      if (
        room.roomRates.length === 1 &&
        TP_REDEMPTION_RATE.includes(room.roomRates[0].rateCode) &&
        redemptionItem &&
        checkinDate &&
        checkoutDate &&
        isLoggedIn
      ) {
        const pointsRequired =
          redemptionItem && redemptionItem?.currencyRequired
            ? parseInt(redemptionItem?.currencyRequired)
            : 0;

        const numNights = differenceInDays(
          new Date(checkoutDate),
          new Date(checkinDate)
        );
        const memberPoints = store.getState().member?.memberPointsTally;
        const isEligible = checkUserRedemptionEligiblity(
          memberPoints,
          pointsRequired,
          numNights
        );
        if (!isEligible) {
          includeRedemptionRate = false;
        }
      }
      room.roomRates.forEach((rate: any) => {
        const defaultTypes = ["public", "member", "package", null, ""];
        const rateObject = {
          ...rate,
          RateCode: rate.rateCode,
        };
        const isRedemptionRate = TP_REDEMPTION_RATE.includes(rate.rateCode);

        if (!isRedemptionRate) {
          rates.push(rateObject);
        } else if (
          isRedemptionRate &&
          isLoggedIn &&
          !isMultiroom &&
          !isRedemptionPromoCode &&
          includeRedemptionRate
        ) {
          rates.push(rateObject);
        }
        if (!isRedemptionRate) {
          if (
            promotionCode !== null ||
            groupCode !== null ||
            discountCode !== null
          ) {
            selectedRates.push(rate);
          } else if (defaultTypes.includes(rate.rateType)) {
            selectedRates.push(rate);
          }
        } else if (
          isRedemptionRate &&
          isLoggedIn &&
          !isMultiroom &&
          !isRedemptionPromoCode &&
          includeRedemptionRate
        ) {
          selectedRates.push(rate);
        }
      });
      if (selectedRates.length) {
        selectedRates.sort(
          (a, b) =>
            parseFloat(a?.roomRateWithFees) - parseFloat(b?.roomRateWithFees)
        );
        if (rates.length) {
          rates.sort(
            (a, b) =>
              parseFloat(a?.roomRateWithFees) - parseFloat(b?.roomRateWithFees)
          );
        }
        let baseRate = rates.filter((rateObject) => rateObject.isBaseRate);
        if (!baseRate[0]) {
          baseRate = rates.filter(
            (rateObject) =>
              rateObject.rateType == "public" || rateObject.rateType != "member"
          );
          baseRate.sort(
            (a, b) =>
              parseFloat(a?.roomRateWithFees) - parseFloat(b?.roomRateWithFees)
          );
        }
        let fromRate = {};
        if (
          selectedRates.length > 1 &&
          TP_REDEMPTION_RATE.includes(selectedRates[0].rateCode)
        ) {
          fromRate = {
            roomRate: selectedRates[1].roomRate,
            roomRateWithFees: selectedRates[1]?.roomRateWithFees,
            rateType: selectedRates[1].rateType,
            rateCode: selectedRates[1].rateCode,
            isPromotionalRate: selectedRates[1].isPromotionalRate,
            fees: selectedRates[1].fees,
            feeItems: selectedRates[1].feeItems,
          };
        } else {
          fromRate = {
            roomRate: selectedRates[0].roomRate,
            roomRateWithFees: selectedRates[0]?.roomRateWithFees,
            rateType: selectedRates[0].rateType,
            rateCode: selectedRates[0].rateCode,
            isPromotionalRate: selectedRates[0].isPromotionalRate,
            fees: selectedRates[0].fees,
            feeItems: selectedRates[0].feeItems,
          };
        }
        let discountedRate: {
          roomRate?: string;
          roomRateWithFees?: string;
          rateType?: string;
          rateCode?: string;
          isPromotionalRate?: boolean;
          fees?: number;
          feeItems?: Array<any>;
        } = {};
        let discountedRates;
        if (promotionCode || groupCode || discountCode === "AARP") {
          discountedRates = selectedRates.filter(
            (rateObject) => rateObject && rateObject.isPromotionalRate
          );
        }
        if (discountCode) {
          if (discountCodes.indexOf(discountCode) != -1) {
            discountedRates = selectedRates;
          }
        }
        if (discountedRates && discountedRates.length > 0) {
          discountedRates.sort(
            (a, b) => parseFloat(a.roomRate) - parseFloat(b.roomRate)
          );
          if (
            discountedRates.length > 1 &&
            TP_REDEMPTION_RATE.includes(discountedRates[0].rateCode)
          ) {
            discountedRate = discountedRates[1];
          } else {
            discountedRate = discountedRates[0];
          }
        }
        if (Object.keys(discountedRate).length > 0) {
          fromRate = {
            roomRate: discountedRate.roomRate,
            roomRateWithFees: discountedRate?.roomRateWithFees,
            rateType: discountedRate.rateType,
            rateCode: discountedRate.rateCode,
            isPromotionalRate: discountedRate.isPromotionalRate,
            fees: discountedRate.fees,
            feeItems: discountedRate.feeItems,
          };
        }
        const currency = rates && rates.length ? rates[0].currencyCode : "USD";
        const roomObject = {
          ...room,
          Rates: rates,
          FromRate: fromRate.roomRate,
          FromRateWithFees: fromRate?.roomRateWithFees,
          FromRateCode: fromRate.rateCode,
          FromRateType: fromRate.rateType,
          FromRatePromotional: fromRate.isPromotionalRate,
          FromRateFees: fromRate.fees,
          FromRateFeeItems: fromRate.feeItems,
          BaseRate: baseRate.length ? baseRate[0].roomRate : null,
          BaseRateWithFees: baseRate?.length
            ? baseRate[0]?.roomRateWithFees
            : null,
          BaseRateFees: baseRate.length ? baseRate[0]?.fees : null,
          FeeItems: baseRate.length ? baseRate[0]?.feeItems : null,
          BaseRateCode: baseRate[0] ? baseRate[0].rateCode : null,
          BaseRateType: baseRate[0] ? baseRate[0].rateType : null,
          RoomCode: room.code,
          Currency: currency,
          LowestRate: rates?.reduce((prev, curr) =>
            prev.total < curr.total ? prev : curr
          ).rateCode,
          IsonlyRate: rates?.length === 1,
          RateCount: rates?.length ?? 0,
        };
        filteredRooms.push(roomObject);
      }
    });
    if (filteredRooms.length) {
      // Sort rooms by lowest price.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filteredRooms.sort((f, s) => {
        if (f.FromRatePromotional && s.FromRatePromotional) {
          return (
            parseFloat(f?.FromRateWithFees) - parseFloat(s?.FromRateWithFees)
          );
        } else {
          if (f.FromRatePromotional && !s.FromRatePromotional) {
            return -1;
          }
          if (!f.FromRatePromotional && s.FromRatePromotional) {
            return 1;
          }
          if (!f.Sort && !s.Sort) {
            return (
              parseFloat(f?.FromRateWithFees) - parseFloat(s?.FromRateWithFees)
            );
          }
        }
      });
      return filteredRooms;
    }
  }
  return null;
};
