/**
 * This is the Auth0 client that is designed to be a replacement for the Okta related
 * authClient used in the utils/auth.js module.
 */
import auth0 from "auth0-js";
import Axios, { AxiosResponse } from "axios";
import { navigate } from "gatsby";
import { KJUR } from "jsrsasign";
import jwt_decode from "jwt-decode";
import { Constants } from "../@types/Constants";
import ILoginError from "../@types/ILoginError";
import ILoginUser from "../@types/ILoginUser";
import { IPropertyWifiDetails } from "../@types/IPropertyWifiDetails";
import { guestServiceProvider } from "../services/ServiceProvider";
import { pushErrorInGA } from "./datalayers/index";
import { Storage } from "./storage";

const isBrowser = typeof window !== "undefined";

const GATSBY_AUTH0_DOMAIN =
  process.env.GATSBY_AUTH0_DOMAIN || "sonesta.us.auth0.com";
const GATSBY_AUTH0_CLIENT_ID =
  process.env.GATSBY_AUTH0_CLIENT_ID || "FKrEvaRK4c3EPLbtR3vMb5nQBmL7a6Eh";
const AUTH0_CALLBACK = isBrowser
  ? `${window.location.origin}/callback`
  : "http://localhost:8000/callback";
const AUTH0_LOGOUT_URL = isBrowser
  ? `${window.location.origin}/travel-pass`
  : "http://localhost:8000/travel-pass";

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: GATSBY_AUTH0_DOMAIN,
      clientID: GATSBY_AUTH0_CLIENT_ID,
      redirectUri: AUTH0_CALLBACK,
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : null;

type TokensType = {
  accessToken: string | undefined;
  idToken: string | undefined;
  expiresAt: number | undefined;
};

const tokens: TokensType = {
  accessToken: undefined,
  idToken: undefined,
  expiresAt: undefined,
};

let user = {};

export const isAuthenticated = (): boolean => {
  if (!isBrowser) {
    return false;
  }

  return !!getToken() && Storage.GetLocalStorageValue("isLoggedIn") === "true";
};

export const logout = (): boolean | undefined => {
  if (!isBrowser) {
    return;
  }

  auth &&
    auth.logout({
      returnTo: AUTH0_LOGOUT_URL,
    });
};

const getEmail = async (guestId: string, password: string): Promise<string> => {
  try {
    const result = await Axios.post(
      `${window.location.origin}/api/getEmailFromGuestId`,
      {
        guest_id: guestId,
        password,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    );
    const { email } = result.data;
    return email;
  } catch (err: any) {
    return err.message;
  }
};

/**
 * Get token from Auth0
 * @param username {string}
 * @param password {string}
 * @param callback {function}
 */
export const getCDPToken = async (
  username: string,
  password: string,
  callback: (
    errObj: ILoginError | null,
    data: ILoginUser | Record<string, unknown>
  ) => void
): Promise<void> => {
  try {
    const guestService = await guestServiceProvider();
    const response = await guestService.guestlogin(username, password);
    if (response.auth0TokenInfo.statusCode === "200") {
      user = await jwt_decode(response.auth0TokenInfo.token);
      tokens.accessToken = response?.auth0TokenInfo?.token;
      const ttl =
        new Date(user.exp * 1000 - 3 * 60 * 1000).getTime() -
        new Date().getTime();
      Storage.SetLocalStorageValue("isLoggedIn", "true", ttl);
      Storage.SetLocalStorageValue(
        "auth-token",
        btoa(response.auth0TokenInfo.token),
        ttl
      );
      callback(null, user);
    } else {
      const errObj = {
        error: response.auth0TokenInfo.message,
        errorMessage: response.auth0TokenInfo.message,
        lastEditDate: response.auth0TokenInfo.lastEditDate,
      };
      callback(errObj, { name: "", email: username });
    }
  } catch (error: any) {
    const errObj = error
      ? {
          error: error,
          errorMessage: "Oops! something went wrong, please try again",
          lastEditDate: "",
        }
      : null;
    callback(errObj, { name: "", email: username });
  }
};

export const login = async (
  email: string,
  password: string,
  cb: (
    errObj: ILoginError | null,
    user:
      | ILoginUser
      | {
          [key: string]: unknown;
        }
  ) => void
): Promise<void> => {
  if (!isBrowser) {
    return;
  }

  let username;
  if (email.indexOf("@") === -1) {
    username = await getEmail(email, password);
  } else {
    username = email;
  }

  auth &&
    auth.popup.loginWithCredentials(
      {
        // redirectUri: '/',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        connection: "Guestware",
        username,
        password: password,
        scope: "openid email profile user_metadata",
        response_type: "token id_token",
      },
      (err: null | auth0.Auth0Error, data: any) => {
        if (data && data.accessToken && data.idToken) {
          const expiresAt = data.expiresIn; // * 1000 + new Date().getTime();
          tokens.accessToken = data.accessToken;
          tokens.idToken = data.idToken;
          tokens.expiresAt = expiresAt;
          user = data.idTokenPayload;
          const ttl =
            new Date(data.idTokenPayload.exp * 1000 - 3 * 60 * 1000).getTime() -
            new Date().getTime();
          Storage.SetLocalStorageValue("isLoggedIn", "true", ttl);
          Storage.SetLocalStorageValue("auth-token", btoa(data.idToken), ttl);
        }
        const errObj = err
          ? {
              error: err.error,
              errorMessage: err.error_description,
            }
          : null;
        cb(errObj, user);
      }
    );
};

const setSession =
  (
    cb = () => {
      /* eslint-disable no-console */
      console.info("In the setSession default callback");
    }
  ) =>
  (err: any, authResult: any) => {
    if (err) {
      console.error("Could not set session | error =", err);
      if (
        err.error === "unauthorized" ||
        err.error === "invalid_token" ||
        err.error === "invalid_hash"
      ) {
        navigate("/expired-link");
      } else {
        navigate("/");
      }
      return;
    }

    if (authResult && authResult.access_token && authResult.id_token) {
      tokens.accessToken = authResult.access_token;
      tokens.idToken = authResult.id_token;
      // Calculate expiresAt
      tokens.expiresAt =
        new Date().getTime() +
        (parseInt(authResult.expires_in, 10) * 1000 || 7200 * 1000);

      try {
        user = jwt_decode(authResult.id_token);
      } catch (e) {
        console.error("Error decoding id_token:", e);
        navigate("/expired-link");
        return;
      }

      const ttl =
        new Date(user.exp * 1000 - 3 * 60 * 1000).getTime() -
        new Date().getTime();

      Storage.SetLocalStorageValue("isLoggedIn", "true", ttl);
      Storage.SetLocalStorageValue(
        "auth-token",
        btoa(authResult.id_token),
        ttl
      );
      cb(user);
    } else {
      console.error("Auth result is missing tokens");
      navigate("/expired-link");
    }
  };

export const handleAuthentication = (
  successCallback: (user?: any) => void
): void => {
  if (!isBrowser) {
    return;
  }

  const authResult = parseHash(window.location.hash);

  if (authResult && authResult.access_token && authResult.id_token) {
    setSession(successCallback)(null, authResult);
  } else if (authResult && authResult.error) {
    // Handle error from authResult
    const error = {
      error: authResult.error,
      errorDescription: authResult.error_description,
    };
    setSession(successCallback)(error, null);
  } else {
    const error = {
      error: "invalid_hash",
      errorDescription: "Could not retrieve tokens from the URL hash.",
    };
    setSession(successCallback)(error, null);
  }
};

const parseHash = (hash: string): any => {
  if (!hash) {
    return null;
  }

  const authResult: any = {};
  const queryString = hash.substring(1); // Remove the '#'
  const regex = /([^&=]+)=([^&]*)/g;
  let m;

  while ((m = regex.exec(queryString))) {
    authResult[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
  }

  return authResult;
};

export const getProfile = (): Record<string, unknown> => {
  return user;
};

export const getToken = (): string => {
  const tokenFromStorage = Storage.GetLocalStorageValue("auth-token")
    ? atob(Storage.GetLocalStorageValue("auth-token"))
    : "";
  return tokens?.idToken || tokenFromStorage;
};

const MINIMUM_RETRY_DELAY_MS = 800;
const MAX_RETRIES = 3;
const execWithRetry = (
  requestPromise: Promise<AxiosResponse<any>>,
  retriesLeft = 3
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    const invokeRetry = () => {
      setTimeout(async () => {
        try {
          const childRet = await execWithRetry(requestPromise, retriesLeft - 1);
          resolve(childRet);
        } catch (err) {
          reject(err);
        }
      }, MINIMUM_RETRY_DELAY_MS * MAX_RETRIES);
    };
    // eslint-disable-next-line no-console
    console.info(">>> execWithRetry | TOP | retriesLeft =", retriesLeft);
    requestPromise
      .then((ret) => {
        if (ret.status === 400 || ret.status === 500 || ret.status === 502) {
          // eslint-disable-next-line no-console
          console.info(
            "Need to retry | ret.status =",
            ret.status,
            " | ret.data =",
            ret.data
          );
          if (retriesLeft <= 1) {
            reject(ret);
            return;
          }

          invokeRetry();
        } else if (ret.status >= 200 && ret.status <= 210) {
          resolve(ret);
        } else {
          reject(ret);
        }
      })
      .catch((err) => {
        // Assumption is that the requestPromise is an Axios request promise
        // so the err will have err.response in it.
        const canRetry =
          err.response.status === 400 || err.response.status === 502;
        // eslint-disable-next-line no-console
        console.info(
          ">>> execWithRetry | err.response.status =",
          err.response.status,
          ">>> execWithRetry | err.response.data =",
          err.response.data
        );
        if (canRetry && retriesLeft > 1) {
          invokeRetry();
        } else {
          reject(err);
        }
      });
  });
};

export const getProfileFromGuestware = async (
  idToken: string,
  baseProfile: boolean
): Promise<AxiosResponse<any>> => {
  const endpoint = baseProfile ? `basicProfile` : "profile";

  const getProfileRequestPromise = Axios.get(
    `${window.location.origin}/api/${endpoint}`,
    {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  const result = await execWithRetry(getProfileRequestPromise);

  return result;
};

export const getProfileFromCDP = async (
  idToken: string
): Promise<AxiosResponse<any>> => {
  const endpoint = "baseProfileCDP";

  const getProfileRequestPromise = Axios.get(
    `${window.location.origin}/api/${endpoint}`,
    {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  const result = await execWithRetry(getProfileRequestPromise);

  return result;
};

export const getWifiDetails = (
  properties: IPropertyWifiDetails[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  location: any
): Array<string | null> => {
  if (typeof window === "undefined") return [null, null, null];
  try {
    const params1 = new URLSearchParams(location.search);
    const lspUrl = new URL(params1.get("LSP_URL") || "", "https://sonesta.com");
    if (!lspUrl) return [null, null, null];

    const params2 = new URLSearchParams(lspUrl.search);
    const propertyCode = params2.get("property_code");
    if (!propertyCode) return [null, null, null];

    const property = properties.find((p) => p.crs_code === propertyCode);
    if (!property) {
      pushErrorInGA(
        Constants.ERRORSTYPE.TRAVELPASS,
        Constants.ERRORSSUBTYPE.TRAVELPASS.SIGNUPWIFI,
        `WIFI url property not found. -- ${lspUrl}`
      );
      return [null, null, null];
    }

    return [lspUrl.href, propertyCode, property?.name?.slice(0, 50)];
  } catch (err) {
    pushErrorInGA(
      Constants.ERRORSTYPE.TRAVELPASS,
      Constants.ERRORSSUBTYPE.TRAVELPASS.SIGNUPWIFI,
      `Failed to parse WIFI url. ${err}`
    );
    return [null, null, null];
  }
};

type SignUpCDP = {
  email: string;
  firstname: string;
  lastname: string;
  synxisHotelId: string;
  hotelName: string;
};

export const signUpCDP = async ({
  email,
  firstname,
  lastname,
  synxisHotelId,
  hotelName,
}: SignUpCDP): Promise<AxiosResponse<any>> => {
  Storage.SetLocalStorageValue("_currenturl_", window.location.href);
  try {
    const signUpRequestPromise = Axios.post(
      `${window.location.origin}/api/auth0CDPSignUp`,
      {
        email,
        first_name: firstname,
        last_name: lastname,
        wifi: !!synxisHotelId,
        hotel_name: hotelName,
        hotel_id: synxisHotelId,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    );

    const result = await execWithRetry(signUpRequestPromise);

    return result;
  } catch (err: any) {
    if (err && err.response) {
      throw err.response.data;
    } else {
      throw err;
    }
  }
};

export const signUp = async (
  email: string,
  firstName: string,
  lastName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  enrollment?: any
): Promise<AxiosResponse<any>> => {
  Storage.SetLocalStorageValue("_currenturl_", window.location.href);
  try {
    const signUpRequestPromise = Axios.post(
      `${window.location.origin}/api/auth0SignUp`,
      {
        email,
        first_name: firstName,
        last_name: lastName,
        enrollment,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    );

    const result = await execWithRetry(signUpRequestPromise);
    // const result = await signUpRequestPromise;

    const {
      nonce,
      user_id: auth0UserId,
      app_metadata: { guest_id },
    } = result.data;
    const shouldStopEmail = parseInt(
      process.env.GATSBY_STOP_SIGNUP_EMAIL || "0"
    );
    const resultSendEmail = await Axios.post(
      `${window.location.origin}/api/auth0SendSignUpEmail`,
      {
        auth0UserId,
        email,
        guest_id,
        first_name: firstName,
        last_name: lastName,
        nonce,
        noEmail: !!shouldStopEmail,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    );

    result.data.resultSendEmail = resultSendEmail;
    return result;
  } catch (err: any) {
    if (err && err.response) {
      throw err.response.data;
    } else {
      throw err;
    }
  }
};

export const changePassword = async (
  email: string
): Promise<AxiosResponse<any>> => {
  Storage.SetLocalStorageValue("_currenturl_", window.location.href);

  const sendResetPasswordEmailRequestPromise = Axios.post(
    `${window.location.origin}/api/auth0SendResetPasswordEmail`,
    {
      email,
    },
    {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }
  );
  const resultSendEmail = await execWithRetry(
    sendResetPasswordEmailRequestPromise
  );
  // const resultSendEmail = await sendResetPasswordEmailRequestPromise;

  return resultSendEmail;
};

export const changePasswordCDP = async (
  email: string
): Promise<AxiosResponse<any>> => {
  Storage.SetLocalStorageValue("_currenturl_", window.location.href);

  const sendResetPasswordEmailRequestPromise = Axios.post(
    `${window.location.origin}/api/auth0CDPSendResetPasswordEmail`,
    {
      email,
    },
    {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }
  );
  const resultSendEmail = await execWithRetry(
    sendResetPasswordEmailRequestPromise
  );
  // const resultSendEmail = await sendResetPasswordEmailRequestPromise;
  return resultSendEmail;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateUser = (userInfo: any): void => {
  user = userInfo;
};

export const generateJWTToken = (): string => {
  const header = { alg: "HS256", typ: "JWT" };
  const data = {
    id: 1,
    username: "",
    exp: KJUR.jws.IntDate.get("now + 1hour"),
  };
  const secret =
    process.env.GATSBY_NETLIFY_FUNCTION_JWT_SECRET ||
    "d7Mo1ORbP3PzMzZ3IiesdTuaYOf7d7QTKQfuuNYzO_8";

  const sHeader = JSON.stringify(header);
  const sData = JSON.stringify(data);
  const sJWT = KJUR.jws.JWS.sign("HS256", sHeader, sData, { utf8: secret });

  return sJWT;
};
