import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Storage } from "../../../utils/storage";
import { AppDispatch, AppThunk, RootState } from "../../store";
// import IProfile from '../../../@types/IProfile';
// import { auth } from '../../../utils/auth';
import { getMemberProfile } from "../../../services/loyalty";
import { memberServiceProvider } from "../../../services/ServiceProvider";
// import { updateLoginTime, revokeToken } from '../../../services/crm';
import moment from "moment-mini";
import { cancelRedemtionReservation } from "../../../services/crs";
import { convertArrayToObject } from "../../../services/helpers";
import { getMemberPoints } from "../../../services/redemption";
import { sendLoginStateInGA } from "../../../utils/datalayers";
import { addHiddenIframe, getUserTripsDataforGA } from "../../../utils/helpers";
import { clearRecentSearch } from "../RecentSearch/RecentSearch";

type TReuestStatus = {
  type?: string;
  status?: string;
  cancelID?: string;
};
export interface IMemberState {
  isLoggedIn?: boolean;
  profile?: any;
  crmProfile?: any;
  crmBasicProfile?: any;
  upcomingReservations?: Array<any>;
  recentStays?: Array<any>;
  cancelledReservations?: Array<any>;
  error?: any;
  updateRequestStatus?: TReuestStatus;
  profileModal?: boolean;
}
const initialState = {
  isLoggedIn: false,
  profile: {},
  crmProfile: {},
  crmBasicProfile: {},
  upcomingReservations: [],
  recentStays: [],
  cancelledReservations: [],
  error: null,
  updateRequestStatus: {
    type: null,
    status: null,
    cancelID: null,
  },
  profileModal: false,
  memberPointsTally: 0,
};

const memberSlice = createSlice({
  name: "member",
  initialState: initialState,
  reducers: {
    setCRMProfile: (state, { payload }: PayloadAction<any>) => {
      state.crmProfile = payload;
      return state;
    },
    setCRMBasicProfile: (state, { payload }: PayloadAction<any>) => {
      state.crmBasicProfile = payload;
      return state;
    },
    setMember: (state, { payload }: PayloadAction<any>) => {
      state.isLoggedIn = payload.isLoggedIn;
      state.profile = payload.profile;
      return state;
    },
    clearMember: (state) => {
      state = initialState;
      Storage.ClearLocalStorageValue("isLoggedIn");
      Storage.ClearLocalStorageValue("auth-token");
      return state;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      return state;
    },
    clearError: (state) => {
      state.error = null;
      return state;
    },
    updateRequestStatus: (state, { payload }: PayloadAction<any>) => {
      state.updateRequestStatus = payload;
      return state;
    },
    clearUpdateRequestStatus: (state) => {
      state.updateRequestStatus = {
        type: null,
        status: null,
        cancelID: null,
      };
      return state;
    },
    setUpcomingRes: (state, { payload }: PayloadAction<any>) => {
      state.upcomingReservations = payload;
      return state;
    },
    setRecentStays: (state, { payload }: PayloadAction<any>) => {
      state.recentStays = payload;
      return state;
    },
    setCancelledRes: (state, { payload }: PayloadAction<any>) => {
      state.cancelledReservations = payload;
      return state;
    },
    setMemberPointsTally: (state, { payload }: PayloadAction<any>) => {
      state.memberPointsTally = payload;
      return state;
    },
  },
});

export const memberReducer = memberSlice.reducer;
export const {
  setMember,
  setCRMProfile,
  setCRMBasicProfile,
  clearError,
  setUpcomingRes,
  setRecentStays,
  setCancelledRes,
  clearMember,
  updateRequestStatus,
  clearUpdateRequestStatus,
  setMemberPointsTally,
} = memberSlice.actions;

//Async actions

export const login =
  (data?: any | undefined): AppThunk =>
  async (dispatch: AppDispatch) => {
    // await auth.handleAuthentication(data);
    const guest = {
      firstName: data.givenName,
      lastName: data.familyName,
      email: data.email,
      // sessionToken: data.sessionToken,
    };
    // updateLoginTime(data.user.profile.login);
    dispatch(setMember({ profile: guest, isLoggedIn: true }));
    // sendLoginStateInGA(true, window.location.href);
  };

export const logout = (): AppThunk => async (dispatch: AppDispatch) => {
  await dispatch(clearMember());
  await dispatch(clearRecentSearch());
  sendLoginStateInGA(false, window.location.href, null);
  if (window.location.pathname.indexOf("/account") == -1) {
    addHiddenIframe("authIframe", "/auth-logout"); //no redirection after logout
  } else {
    addHiddenIframe("authIframe", "/auth-logout"); //no redirection after logout
  }
};

export const addCRMProfile =
  (tokenId: string, baseProfile = false): AppThunk =>
  async (dispatch: AppDispatch, _getState) => {
    if (tokenId) {
      const profile = await getMemberProfile(tokenId, baseProfile);
      if (profile == "forbidden") {
        await dispatch(clearMember());
      } else {
        if (profile) {
          dispatch(memberSlice.actions.setCRMProfile(profile));
        } else {
          dispatch(
            memberSlice.actions.setError("Error in fetching the profile")
          );
        }
      }
    }
  };
export const addCRMBasicProfileCDP =
  (tokenId: string, baseProfile = false): AppThunk =>
  async (dispatch: AppDispatch, _getState) => {
    if (tokenId) {
      try {
        const memberService = await memberServiceProvider();
        const profile = await memberService.getUserProfile();
        const userId = profile?.memberBasicProfile?.memberId;

        const fetchAndDispatchReservations = async (tripType: string) => {
          const response = await memberService.getMemberTripHistory({
            tripType,
          });
          const results = response?.memberTripHistory?.results || [];

          dispatch(addReservations(results, tripType));
        };

        await fetchAndDispatchReservations("CURRENT");
        await fetchAndDispatchReservations("PAST");
        await fetchAndDispatchReservations("CANCELLED");

        if (userId) {
          const response = await getMemberPoints(userId);
          if (
            response?.memberPointsData &&
            response?.memberPointsData?.available
          ) {
            dispatch(setMemberPointsTally(response.memberPointsData.available));
          } else {
            dispatch(setMemberPointsTally(0));
          }
          const { upcomingReservations, recentStays } = _getState().member;
          const userTripData = getUserTripsDataforGA(
            recentStays,
            upcomingReservations
          );
          const userData = {
            memberId: userId,
            profileType: profile?.cDPUserProfile
              ? profile?.cDPUserProfile?.profileType
              : "NA",
            upcomingTrip: userTripData?.upcomingTrip ? "Yes" : "No",
            daysLeftForUpcomingTrip: userTripData?.daysLeftForUpcomingTrip
              ? userTripData?.daysLeftForUpcomingTrip
              : "NA",
            hotelIdOfLastStay: userTripData?.hotelIdOfLastStay
              ? userTripData?.hotelIdOfLastStay
              : "NA",
            rewardPoints: response?.memberPointsData
              ? response.memberPointsData.available
              : 0,
          };
          sendLoginStateInGA(true, window.location.href, userData);
        }
        dispatch(
          memberSlice.actions.setCRMBasicProfile(profile?.memberBasicProfile)
        );
      } catch (error) {
        dispatch(memberSlice.actions.setError("Error in fetching the profile"));
        console.error(error);
        throw error;
      }
    }
  };

export const addReservations =
  (trips: Array<any>, type: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    if (trips.length > 0) {
      const parseDateFormat = "MM/DD/YYYY h:mm:ss a";
      const reservations = trips.flatMap((stay: any) => {
        const trip = stay.reservations[0];
        if (trip?.confirmNumber) {
          return [
            {
              arrival: moment(trip.startDate).format(parseDateFormat),
              departure: moment(trip.endDate).format(parseDateFormat),
              crs_reservation_id: trip.confirmNumber,
              confirmationNo: trip.confirmNumber,
              reservationstatus: trip.status,
              pms_property_code: trip.LocationID,
              guestFirstName: trip.guestFirstName,
              guestLastName: trip.guestLastName,
              redeemedPoints: trip.redemption?.redeemedPoints,
              hotelId: trip.hotel?.crsHotelCode,
            },
          ];
        }
        return [];
      });
      let reservationsObject: any;
      switch (type) {
        case "PAST":
          reservationsObject = convertArrayToObject(
            reservations,
            "crs_reservation_id"
          );
          dispatch(setRecentStays(reservationsObject));
          break;
        case "CANCELLED":
          reservationsObject = convertArrayToObject(
            reservations,
            "crs_reservation_id"
          );
          dispatch(setCancelledRes(reservationsObject));
          break;
        case "CURRENT":
          reservationsObject = convertArrayToObject(
            reservations,
            "crs_reservation_id"
          );
          dispatch(setUpcomingRes(reservationsObject));
          break;
      }
    }
  };

export const cancelReservation =
  (params: {
    hotelCode: string;
    resId: string;
    memberId: string;
    isRedemption: boolean;
    lastName: string;
  }): any =>
  async (dispatch: AppDispatch, getState) => {
    // const cancelledResponse: any = await cancelReservationAPI(params);
    //const guestService = await guestServiceProvider();
    //const cancelResponse = await guestService.cancelReservation(params.resId);

    try {
      const cancelResponseRedemtion = await cancelRedemtionReservation(params);
      // const cancelID = cancelledResponse.cancelID;
      const cancelID =
        cancelResponseRedemtion?.cancelReservationResponse?.cancellationId;
      if (cancelID) {
        const resId = params.resId;
        const upcomingReservations: any =
          getState().member.upcomingReservations;
        const cancelledReservations: any =
          getState().member.cancelledReservations;

        const { [resId]: changedRes, ...rest } = upcomingReservations;
        const cancelledRes = changedRes
          ? { [resId]: { ...changedRes, cancelID: cancelID } }
          : {};
        dispatch(setUpcomingRes(rest));
        dispatch(
          setCancelledRes({ ...cancelledReservations, ...cancelledRes })
        );
        dispatch(clearError);
        dispatch(
          updateRequestStatus({
            type: "cancel-reservation",
            cancelID: cancelID,
            status: "success",
          })
        );
      } else {
        dispatch(
          updateRequestStatus({
            type: "cancel-reservation",
            status: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        updateRequestStatus({
          type: "cancel-reservation",
          status: "error",
        })
      );
    }
  };

//Selectors
export const isLoggedInSelector = (state: RootState): boolean =>
  state.member.isLoggedIn;
export const crmProfileSelector = (state: RootState): any =>
  state.member.isLoggedIn && state.member.crmProfile;
export const crmBasicProfileSelector = (state: RootState): any =>
  state.member.isLoggedIn && state.member.crmBasicProfile;
